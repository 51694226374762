import * as React from "react";
import { Link } from "gatsby";

import logo from '../img/logo-footer.svg'
import facebook from '../img/social/facebook.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <img
            src={logo}
            alt="Golden Triangle Audubon Society"
            style={{ width: '24em', height: '3em' }}
          />
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: "100vw" }} className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li><Link to="/" className="navbar-item">Announcements</Link></li>
                    <li><Link className="navbar-item" to="/about">About</Link></li>
                    <li><Link className="navbar-item" to="/meetings">Meetings</Link></li>
                    <li><Link className="navbar-item" to="/membership">Membership</Link></li>
                    <li><Link className="navbar-item" to="/newsletters">Newsletters</Link></li>
                    <li><Link className="navbar-item" to="/bird-counts">Bird Counts</Link></li>
                    <li><Link className="navbar-item" to="/focus-areas">Focus Areas</Link></li>
                  </ul>
                </section>
                <div className="column is-4 social">
                  <a title="facebook" href="https://www.facebook.com/GoldenTriangleAudubon/">
                    <img 
                      src={facebook}
                      alt="Facebook"
                      style={{ width: '1em', height: '1em' }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
